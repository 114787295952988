import React, { useState, useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useHistory, useParams } from 'react-router-dom'

import { PageDisplayer } from '../../../../ui'
import { ApplicationDetail, useAppContext } from '../../../../features/application-detail'
import { ERROR_CODES } from '../../../../features/applications/constants/error.constants'

export const ApplicationDetailPage = () => {
  const { appId } = useParams()
  const { formatMessage } = useIntl()
  const { getApplication, error } = useAppContext()
  const history = useHistory()

  const [loading, setLoading] = useState(true)

  useEffect(() => {
    getApplication(appId)
      .then(() => setLoading(false))
      .catch(() => setLoading(false))
  }, [appId]) // eslint-disable-line react-hooks/exhaustive-deps

  const handleGoBack = () => {
    if (history.action !== 'POP' && !history?.location?.pathname?.includes(appId)) {
      // Avoid looping from a subpage, such as the Certifications page, within this application details page
      history.goBack()
    } else {
      history.push('/applications')
    }
  }

  return (
    <PageDisplayer
      loading={loading}
      handleGoBack={handleGoBack}
      textGoBack={formatMessage({ id: 'pages.application.detail.return' })}
    >
      {error ? (
        ERROR_CODES.no_zeus_code_provided.code === error.code ? (
          formatMessage({ id: ERROR_CODES.no_zeus_code_provided.label })
        ) : (
          formatMessage({ id: 'pages.application.detail.error' }, { appId })
        )
      ) : (
        <ApplicationDetail />
      )}
    </PageDisplayer>
  )
}
